const FaqTable = () => {
  return (
    <div>
      <div className="container QuestionsSection">
        <div className="Heading">
          <h2>Frequently Asked Questions</h2>
          <span></span>
        </div>
        <div className="FAQAccordion">
          <div className="Option">
            <input className="Toggle" id="FAQAccordion_1" type="checkbox" />
            <label className="Title" for="FAQAccordion_1">
              Is Felo be Machine Washable & Dry-able?
            </label>
            <div className="Body">
              <p>
                Yes, the Felo pillow is machine washable and dry-able. It is
                recommended to wash it on a gentle cycle and tumble dry on low
                heat.
              </p>
            </div>
          </div>
          <div className="Option">
            <input className="Toggle" id="FAQAccordion_2" type="checkbox" />
            <label className="Title" for="FAQAccordion_2">
              Is Felo suitable for side, back, and stomach sleepers?
            </label>
            <div className="Body">
              <p>
                Felo is designed for anyone who is looking to sleep their way to
                a better day. Whether you sleep on your side, back, stomach, or
                a combination of all three, Felo will provide you with the
                perfect support for your head and neck.
              </p>
            </div>
          </div>
          <div className="Option">
            <input className="Toggle" id="FAQAccordion_3" type="checkbox" />
            <label className="Title" for="FAQAccordion_3">
              Will Felo Lose Its shape over time?
            </label>
            <div className="Body">
              <p>
                No, Felo has been lab tested to retain its shape for as long as
                you sleep on it, even after repeated washing. The CloudFusion
                fill ensures the pillow maintains its fluff and flex over a long
                period of time.
              </p>
            </div>
          </div>
          <div className="Option">
            <input className="Toggle" id="FAQAccordion_4" type="checkbox" />
            <label className="Title" for="FAQAccordion_4">
              Does the Felo Pillow Emit Any Odors When Opening?
            </label>
            <div className="Body">
              <p>
                We use world-class materials that are odor-free and do not emit
                any chemical off-gassing. Felo is completely natural and
                chemical-free.
              </p>
            </div>
          </div>
          <div className="Option">
            <input className="Toggle" id="FAQAccordion_5" type="checkbox" />
            <label className="Title" for="FAQAccordion_5">
              How does the 90 day satisfaction guarantee work?
            </label>
            <div className="Body">
              <p>
                Try it for 90 days entirely at our risk. If you are not
                absolutely delighted with the Felo pillow from the very first
                night, simply return it for a full refund. Contact our full-time
                dedicated support team for assistance, no questions asked.
              </p>
            </div>
          </div>
          <div className="Option">
            <input className="Toggle" id="FAQAccordion_6" type="checkbox" />
            <label className="Title" for="FAQAccordion_6">
              What is the pillow cover made out of?
            </label>
            <div className="Body">
              <p>
                The outer cover of Felo is crafted from the finest 500-thread
                count USA Cotton and is Oeko-Tex certified. It provides a cool,
                soft surface for ultimate comfort.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqTable;
