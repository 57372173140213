const Testimonials = () => {
  return (
    <div>
      <div id="testimonials-link" className="get-sec">
        <div className="get-con">
          <div className="hero-split-mob">
            <div
              style={{ paddingTop: "56.27659574468085%" }}
              className="no-spe-vid w-video w-embed"
            >
              <iframe
                className="embedly-embed"
                src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Ffast.wistia.net%2Fembed%2Fiframe%2F2bkalo5vr0&display_name=Wistia%2C+Inc.&url=https%3A%2F%2Fgetnuzzle.wistia.com%2Fmedias%2F2bkalo5vr0&image=https%3A%2F%2Fembed-ssl.wistia.com%2Fdeliveries%2Fc3958f152747846968f93f281fc2a1db.jpg%3Fimage_crop_resized%3D960x540&key=c4e54deccf4d4ec997a64902e9a30300&type=text%2Fhtml&schema=wistia"
                width="940"
                height="529"
                scrolling="no"
                allowfullscreen=""
                title="felopillow_120_2999_20_NO_CTA"
              ></iframe>
            </div>
          </div>
          <div className="hero-wrapper">
            <div className="hero-split">
              <div className="div-block-686">
                <div className="con-star-block">
                  <div>
                    <span className="text-span-142"></span>
                  </div>
                </div>
                <div>
                  <div className="vid-rev-text">10,000+ Five Star Reviews</div>
                </div>
              </div>
              <h1 className="new-con-heading">
                Discover the Easiest Way to get a Good Night&#x27;s Sleep and
                Wake Up Refreshed
              </h1>
              <div className="full-sep-div"></div>
              <p className="margin-bottom-24px">
                Imagine waking up refreshed, free from pain, ready to tackle the
                day ahead.
                <br />‍<br />
                Felo is designed to do just that: Alleviate neck and back pain,
                banish migraines, and ensure a rejuvenating sleep. Join
                thousands who are already experiencing these benefits.
              </p>
            </div>
            <div className="hero-split-desk">
              <div
                style={{ paddingTop: "56.27659574468085%" }}
                className="no-spe-vid w-video w-embed"
              >
                <iframe
                  className="embedly-embed"
                  src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Ffast.wistia.net%2Fembed%2Fiframe%2F2bkalo5vr0&display_name=Wistia%2C+Inc.&url=https%3A%2F%2Fgetnuzzle.wistia.com%2Fmedias%2F2bkalo5vr0&image=https%3A%2F%2Fembed-ssl.wistia.com%2Fdeliveries%2Fc3958f152747846968f93f281fc2a1db.jpg%3Fimage_crop_resized%3D960x540&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=wistia"
                  width="940"
                  height="529"
                  scrolling="no"
                  allowfullscreen=""
                  title="felopillow_120_2999_20_NO_CTA"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="testimonials-link" className="ret-sec">
        <div className="two-content-block">
          <div className="hero-split-mob">
            <div className="team-grid">
              <div
                id="w-node-_22b7888d-692b-2cc1-4ddc-b9564391fc8b-c00d1fb3"
                className="team-card"
              >
                <img
                  src="images/01.webp"
                  loading="lazy"
                  sizes="100vw"
                  srcset="
                            images/01-p-500.webp  500w,
                            images/01-p-800.webp  800w,
                            images/01.webp       1000w
                          "
                  alt=""
                  className="team-member-image"
                />
                <div className="team-member-name">Neck Pain</div>
              </div>
              <div
                id="w-node-_22b7888d-692b-2cc1-4ddc-b9564391fc8f-c00d1fb3"
                className="team-card"
              >
                <img
                  src="images/02.webp"
                  loading="lazy"
                  sizes="100vw"
                  srcset="
                            images/02-p-500.webp  500w,
                            images/02-p-800.webp  800w,
                            images/02.webp       1001w
                          "
                  alt=""
                  className="team-member-image"
                />
                <div className="team-member-name">Back Pain</div>
              </div>
              <div
                id="w-node-_22b7888d-692b-2cc1-4ddc-b9564391fc93-c00d1fb3"
                className="team-card"
              >
                <img
                  src="images/03.webp"
                  loading="lazy"
                  sizes="(max-width: 479px) 97vw, 100vw"
                  srcset="
                            images/03-p-500.webp  500w,
                            images/03-p-800.webp  800w,
                            images/03.webp       1001w
                          "
                  alt=""
                  className="team-member-image"
                />
                <div className="team-member-name">Head Ache</div>
              </div>
              <div
                id="w-node-_22b7888d-692b-2cc1-4ddc-b9564391fc97-c00d1fb3"
                className="team-card"
              >
                <img
                  src="images/04_1.webp"
                  loading="lazy"
                  sizes="100vw"
                  srcset="
                            images/04_1-p-500.webp  500w,
                            images/04_1-p-800.webp  800w,
                            images/04_1.webp       1000w
                          "
                  alt=""
                  className="team-member-image"
                />
                <div className="team-member-name">
                  Shoulder Pain
                  <br />
                </div>
              </div>
              <div
                id="w-node-_22b7888d-692b-2cc1-4ddc-b9564391fc9c-c00d1fb3"
                className="team-card"
              >
                <img
                  src="images/05.webp"
                  loading="lazy"
                  sizes="100vw"
                  srcset="
                            images/05-p-500.webp  500w,
                            images/05-p-800.webp  800w,
                            images/05.webp       1001w
                          "
                  alt=""
                  className="team-member-image"
                />
                <div className="team-member-name">Numbness &amp; Tingling</div>
              </div>
              <div
                id="w-node-_22b7888d-692b-2cc1-4ddc-b9564391fca0-c00d1fb3"
                className="team-card"
              >
                <img
                  src="images/06.webp"
                  loading="lazy"
                  sizes="(max-width: 479px) 97vw, 100vw"
                  srcset="
                            images/06-p-500.webp  500w,
                            images/06-p-800.webp  800w,
                            images/06.webp       1001w
                          "
                  alt=""
                  className="team-member-image"
                />
                <div className="team-member-name">Muscle Tension</div>
              </div>
            </div>
          </div>
          <div className="rev-hero-wrapper">
            <div className="hero-split">
              <h1 className="new-con-heading">
                <span className="text-span-143">
                  Professionally Recommended
                </span>{" "}
                to STOP the Damage Caused by your Old Pillow
              </h1>
              <div className="full-sep-div"></div>
              <p className="margin-bottom-24px">
                If you are constantly tossing, turning, flipping, and fluffing
                your pillow, only to wake up with aches and pains, it&#x27;s a
                clear sign that your old pillow is doing damage to your
                well-being. 
                <br />
                <br />
                Felo automatically positions your body so comfortably without
                putting any pressure on your neck or head. Its like magic –
                you just must try it!
              </p>
              <div className="list-div-block">
                <div className="list-div-inner">
                  <div className="dis-left-div">
                    <img
                      src="images/tick-blue.svg"
                      loading="lazy"
                      alt=""
                      className="tt-bb-svg"
                    />
                  </div>
                  <div className="dis-lright-div">
                    <div className="list-inner-txt">
                      Recommended by Professionals Nationwide
                    </div>
                  </div>
                </div>
                <div className="list-div-inner">
                  <div className="dis-left-div">
                    <img
                      src="images/tick-blue.svg"
                      loading="lazy"
                      alt=""
                      className="tt-bb-svg"
                    />
                  </div>
                  <div className="dis-lright-div">
                    <div className="list-inner-txt">
                      Helps you get back to an active life pain-free &amp;
                      energized
                    </div>
                  </div>
                </div>
                <div className="list-div-inner">
                  <div className="dis-left-div">
                    <img
                      src="images/tick-blue.svg"
                      loading="lazy"
                      alt=""
                      className="tt-bb-svg"
                    />
                  </div>
                  <div className="dis-lright-div">
                    <div className="list-inner-txt">
                      For a limited time get the{" "}
                      <strong>BIGGEST DISCOUNT OF 60% OFF!</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div className="meet_wrapper">
                <div className="meet_content">
                  <div className="meet-sub-heading">
                    Meet Our Expert Advisor
                  </div>
                  <div className="meet-quote-text">
                    &quot;I&#x27;ve reviewed numerous pillows, yet Felo
                    consistently ranks at the top of my list!&quot;
                  </div>
                  <div className="meet-author-text">
                    Dr. Patrick Silva, Consultant to Felo
                  </div>
                </div>
                <div className="meet_image">
                  <img
                    src="images/dr_pi_1dr_pi.webp"
                    loading="lazy"
                    alt=""
                    className="meet-img"
                  />
                </div>
              </div>
            </div>
            <div className="hero-split-desk">
              <div className="team-grid">
                <div
                  id="w-node-_22b7888d-692b-2cc1-4ddc-b9564391fcd6-c00d1fb3"
                  className="team-card"
                >
                  <img
                    src="images/fr1-1.webp"
                    loading="lazy"
                    sizes="(max-width: 479px) 100vw, (max-width: 991px) 120px, (max-width: 1279px) 14vw, 167.3203125px"
                    srcset="
                              images/fr1-1-p-500.webp  500w,
                              images/fr1-1-p-800.webp  800w,
                              images/fr1-1.webp       1000w
                            "
                    alt=""
                    className="team-member-image"
                  />
                  <div className="team-member-name">Neck Pain</div>
                </div>
                <div
                  id="w-node-_22b7888d-692b-2cc1-4ddc-b9564391fcda-c00d1fb3"
                  className="team-card"
                >
                  <img
                    src="images/fr2-1.webp"
                    loading="lazy"
                    sizes="(max-width: 479px) 100vw, (max-width: 991px) 120px, (max-width: 1279px) 14vw, 167.3359375px"
                    srcset="
                              images/fr2-1-p-500.webp  500w,
                              images/fr2-1-p-800.webp  800w,
                              images/fr2-1.webp       1001w
                            "
                    alt=""
                    className="team-member-image"
                  />
                  <div className="team-member-name">Back Pain</div>
                </div>
                <div
                  id="w-node-_22b7888d-692b-2cc1-4ddc-b9564391fcde-c00d1fb3"
                  className="team-card"
                >
                  <img
                    src="images/fr3-1.webp"
                    loading="lazy"
                    sizes="(max-width: 479px) 100vw, (max-width: 991px) 120px, (max-width: 1279px) 14vw, 167.328125px"
                    srcset="
                              images/fr3-1-p-500.webp  500w,
                              images/fr3-1-p-800.webp  800w,
                              images/fr3-1.webp       1001w
                            "
                    alt=""
                    className="team-member-image"
                  />
                  <div className="team-member-name">Migraines</div>
                </div>
                <div
                  id="w-node-_22b7888d-692b-2cc1-4ddc-b9564391fce2-c00d1fb3"
                  className="team-card"
                >
                  <img
                    src="images/fr4-1.webp"
                    loading="lazy"
                    sizes="(max-width: 479px) 100vw, (max-width: 991px) 120px, (max-width: 1279px) 14vw, 167.3203125px"
                    srcset="
                              images/fr4-1-p-500.webp  500w,
                              images/fr4-1-p-800.webp  800w,
                              images/fr4-1.webp       1000w
                            "
                    alt=""
                    className="team-member-image"
                  />
                  <div className="team-member-name">
                    Shoulder Pains
                    <br />
                  </div>
                </div>
                <div
                  id="w-node-_22b7888d-692b-2cc1-4ddc-b9564391fce7-c00d1fb3"
                  className="team-card"
                >
                  <img
                    src="images/fr5-1.webp"
                    loading="lazy"
                    sizes="(max-width: 479px) 100vw, (max-width: 991px) 120px, (max-width: 1279px) 14vw, 167.3359375px"
                    srcset="
                              images/fr5-1-p-500.webp  500w,
                              images/fr5-1-p-800.webp  800w,
                              images/fr5-1.webp       1001w
                            "
                    alt=""
                    className="team-member-image"
                  />
                  <div className="team-member-name">
                    Numbness &amp; Tingling
                  </div>
                </div>
                <div
                  id="w-node-_22b7888d-692b-2cc1-4ddc-b9564391fceb-c00d1fb3"
                  className="team-card"
                >
                  <img
                    src="images/fr6-1.webp"
                    loading="lazy"
                    sizes="(max-width: 479px) 100vw, (max-width: 991px) 120px, (max-width: 1279px) 14vw, 167.328125px"
                    srcset="
                              images/fr6-1-p-500.webp  500w,
                              images/fr6-1-p-800.webp  800w,
                              images/fr6-1.webp       1001w
                            "
                    alt=""
                    className="team-member-image"
                  />
                  <div className="team-member-name">Muscle Tension</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="testimonials-link" className="section_features">
        <div className="features_wrapper">
          <div className="features_components">
            <div className="features_heading-block">
              <h3 className="features_heading-text">
                The Secret is CloudFusion<strong></strong> Fill
              </h3>
            </div>
            <div className="w-layout-grid grid">
              <div
                id="w-node-cb874fb1-9592-701f-1dd5-7f1e4eea0497-c00d1fb3"
                className="features-blocks is-one"
              >
                <img
                  src="images/01_1.webp"
                  loading="lazy"
                  sizes="(max-width: 479px) 69vw, 85px"
                  srcset="
                            images/01_1-p-500.webp   500w,
                            images/01_1-p-800.webp   800w,
                            images/01_1-p-1080.webp 1080w,
                            images/01_1-p-1600.webp 1600w,
                            images/01_1.webp        1650w
                          "
                  alt=""
                  className="features-icons-svg"
                />
                <div className="features-texts">
                  Gently cradles the head and supports the neck
                </div>
              </div>
              <div
                id="w-node-ebc748e1-4487-5bb4-a259-360adc1fe922-c00d1fb3"
                className="features-blocks is-two"
              >
                <img
                  src="images/02_1.webp"
                  loading="lazy"
                  sizes="(max-width: 479px) 69vw, 85px"
                  srcset="
                            images/02_1-p-500.webp   500w,
                            images/02_1-p-800.webp   800w,
                            images/02_1-p-1080.webp 1080w,
                            images/02_1-p-1600.webp 1600w,
                            images/02_1.webp        1652w
                          "
                  alt=""
                  className="features-icons-svg"
                />
                <div className="features-texts">
                  Lab tested to maintain the supportive shape overtime
                </div>
              </div>
              <div
                id="w-node-b17f1ca6-24ac-2b30-01ba-f41d518175a1-c00d1fb3"
                className="features-blocks is-three"
              >
                <img
                  src="images/03_1.webp"
                  loading="lazy"
                  sizes="(max-width: 479px) 69vw, 85px"
                  srcset="
                            images/03_1-p-500.webp   500w,
                            images/03_1-p-800.webp   800w,
                            images/03_1-p-1080.webp 1080w,
                            images/03_1-p-1600.webp 1600w,
                            images/03_1.webp        1652w
                          "
                  alt=""
                  className="features-icons-svg"
                />
                <div className="features-texts">
                  Thermoregulation for the optimal sleep temperature
                </div>
              </div>
              <div
                id="w-node-_38dddc9b-8516-ae9b-b1d1-e115a6e124b0-c00d1fb3"
                className="features-blocks is-four"
              >
                <img
                  src="images/04_2.webp"
                  loading="lazy"
                  sizes="(max-width: 479px) 69vw, 85px"
                  srcset="
                            images/04_2-p-500.webp   500w,
                            images/04_2-p-800.webp   800w,
                            images/04_2-p-1080.webp 1080w,
                            images/04_2-p-1600.webp 1600w,
                            images/04_2.webp        1651w
                          "
                  alt=""
                  className="features-icons-svg"
                />
                <div className="features-texts">
                  Promotes perfect spinal alignment in any sleep position
                </div>
              </div>
              <div
                id="w-node-_8ce9d98e-32a6-7c26-5845-f4ebc120c74a-c00d1fb3"
                className="empty_circle"
              ></div>
            </div>
          </div>
          <div className="features_components-two main-home main-home-desktop">
            <div className="div-block-885">
              <div className="float-div">
                <img
                  src="images/64c36fbd5ad4485eeded2d49_01.svg"
                  loading="lazy"
                  alt=""
                  className="float-icon-svg"
                />
              </div>
            </div>
          </div>
          <div className="features_components-two main-home main-home-mobile">
            <div className="div-block-885">
              <div className="float-div">
                <img
                  src="images/01.svg"
                  loading="lazy"
                  alt=""
                  className="float-icon-svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
